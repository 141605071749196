<template>
  <div class="statement-detail">
    <app-header :title="title" :isShowBack="true"></app-header>
    <app-overview title="// 提款详情 //" :list="actualList"></app-overview>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      title: "提款详情",
      actualList: [
        {
          label: "提款流水号",
          key: "withdrawId",
          value: ""
        },
        {
          label: "提款人",
          key: "withdrawAdult",
          value: ""
        },
        {
          label: "提款时间",
          key: "withdrawTime",
          value: ""
        },
        {
          label: "提款金额",
          key: "withdrawAmount",
          value: ""
        },
        {
          label: "到帐情况",
          key: "arrivalState",
          value: ""
        },
        {
          label: "预计到账时间",
          key: "arrivalTime",
          value: ""
        },
        {
          label: "提款说明",
          key: "withdrawRemark",
          value:
            "入驻威富智慧公寓平台后，从平台提取房客缴纳房租，水电费，押金等费用，不扣取任何服务费用，提款申请提交成功后，正常在1-3个工作日后到账。"
        },
        {
          label: "",
          key: "",
          value: ""
        }
      ],
      tit: "",
      opt: {
        title: "账单明细(" + _this.cache.get("statementDetailTitle", "") + ")",
        isShowBack: true,
        search: [
          {
            key: "reconState",
            label: "对账状态",
            type: "select",
            opt: {
              list: [
                {
                  label: "正常",
                  value: 1
                },
                {
                  label: "异常",
                  value: 2
                },
                {
                  label: "已冲正",
                  value: 3
                }
              ]
            }
          }
        ]
      }
    };
  },
  activated() {
    this.onGet();
  },
  methods: {
    onGet(opt) {
      // console.log(this.$route.query.withdrawId)
      let dto = {
        withdrawId: this.$route.query.withdrawId
      };
      this.post("/finance-service/withdraw/withdrawRecordDetail", dto, {
        isUseResponse: true
      }).then(res => {
        console.log(res);
        var data = res.data.data;
        var keys = [];
        for (var key in data) {
          keys.push(key);
        }

        this.actualList.forEach(item => {
          item.value = data[item.key];
          if (item.key == "arrivalState") {
            item.value = data["arrivalState"] == "1" ? "到账" : "未到账";
          }
          if (item.key == "withdrawRemark") {
            item.value =
              "入驻威富智慧公寓平台后，从平台提取房客缴纳房租，水电费，押金等费用，不扣取任何服务费用，提款申请提交成功后，正常在1-3个工作日后到账。";
          }
          if (item.key == "arrivalTime") {
            if (data["arrivalState"] == "1") {
              item.label = "到账时间";
            } else {
              item.label = "预计到账时间";
              item.value = "三个工作日内";
            }
          }
        });
      });
    }
  }
};
</script>

<style >
  .statement-detail .el-col-12 {
      height: 120px!important;
    }
</style>